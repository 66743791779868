/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import './styles/variables.scss';

// start the Stimulus application
import './bootstrap';

var heroSection = document.querySelector('.js-hero-section');
var heroSlider = document.querySelector('.js-hero-slider');
var notificationBanner = document.querySelector('.js-notification-banner');
var notificationBannerClose = document.querySelector('.js-notification-banner-close');

if(heroSection && heroSlider) {
    if(heroSlider.classList.contains("no-highlighted-items")) {
        heroSection.classList.add("no-highlighted-items");
    }
}

if(notificationBanner) {
    notificationBannerClose.addEventListener("click", () => {
        notificationBanner.classList.add('hidden');
    });
}